.form-control {
    &.form-input {
        background-color: transparent;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid $white;
        color: $white;
        resize: none;
        width: 100%;
        &:focus {
            box-shadow: none !important;
        }
        &.placeholder-white::placeholder {
            color: rgba($color: $white, $alpha: 0.65);
        }
        &.is-invalid {
            border-color: #ee5555 !important;
        }

        & + .form-label {
            background-color: transparent;
            color: rgba($color: $white, $alpha: 0.75) !important;
            &::after {
                background-color: transparent !important;
            }
        }
       
    }
    &.register-input {
        background: transparent;
        border-color: rgba($color: $darkblue, $alpha: 0.5) !important;
        &:focus {
            box-shadow: none !important;
        }
        &.is-invalid {
            & {
                background-color: rgba($color: #ee5555, $alpha: 0.3);
            }
            border-color: #ee5555 !important;
        }
        &.register-password {
            padding-right: 40px;
        }
    }
    &.finish-input {
        border-radius: 0.5rem;
        border-top: 2px solid #e5e5e5;
        border-right: 2px solid #e5e5e5;
        border-bottom: 5px solid #e5e5e5;
        border-left: 2px solid #e5e5e5;
        padding: 1rem;
        &:focus{
            box-shadow: none;

        }
    }
}
.register-label {
    color: $darkblue;
    font-size: 0.8rem;
    letter-spacing: 0.04rem;
}
