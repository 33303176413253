@import "./colors.scss";

.send-request{
    background-color: $darkblue;
    width: 100dvw;
    height: 100dvh;
    padding: 2.1rem 0;
}
.request-title{
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.7rem; /* 150% */
letter-spacing: 0.09rem;

}