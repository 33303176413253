@import "./assets/scss/style.scss";
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Gopher Text", sans-serif;
  color: $white;
  background-color: $darkblue;
}
.page-wrapper {
  width: 100%;
  background-color: $darkblue;
}

a {
  text-decoration: none;
  color: inherit;
}
.fixed-page-wrapper {
  margin-top: 110px;
  min-height: 100%;
}
@media (max-width: 1024px) {
  .fixed-page-wrapper {
    margin-top: 75px !important;
  }
}
.about-text-title {
  font-size: 2.3rem;
  font-weight: 400;
  line-height: 3.6rem; /* 156.522% */
}
.about-paragraph {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.3rem; /* 162.5% */
}
.password-visible {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.sozlesme {
  margin-top: 6.5rem;
  .sozlesme-title {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 3.6rem; /* 144% */
  }
  .sozlesme-main-text {
    color: rgba(255, 255, 255, 0.5);

    font-size: 1rem;
    font-weight: 500;
    line-height: 2.15rem; /* 215% */
  }
  .sozlesme-full {
    margin-top: 5.5rem;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.15rem; /* 215% */

    .madde-baslik {
      font-size: 1.1rem;
      font-weight: 600;
      color: $white;
      line-height: 2.15rem; /* 195.455% */
    }
  }
}
.Toastify__toast-container {
  z-index: 9999999;
}

.loader {
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #1111, $alpha: 0.1);
  display: grid;
  place-items: center;
}
.small-popup {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: $black, $alpha: 0.2);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  &.active {
    display: flex;
  }
  .popup-body {
    background-color: $white;
    width: 50%;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 1rem;
  }
}
.mt10 {
  margin-top: 10rem;
}

@media (max-width: 990px) {
  .small-popup {
    padding: 1rem;
    .popup-body {
      width: 100% !important;
    }
  }
}

.payment-select {
  margin-bottom: 2rem;
  .card-title {
    color: rgba(34, 34, 34, 0.5);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.05rem; /* 128.125% */
    letter-spacing: 0.08rem;
    margin-bottom: 0.85rem;
  }
}
.status-circle {
  width: 6.1rem;
  height: 6.1rem;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  &.circle-green {
    background-color: #58cc02;
  }
  &.circle-red {
    background-color: #d24545;
  }
}
.status-title {
  color: #222;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem; /* 106.667% */
}
.roundedthreehalf {
  border-radius: 0.75rem;
}
.report-paper {
  width: 210mm;
  min-height: 297mm;
  background-color: #06141a;
  background-image: url("../public/images/bg-image-two.png");
  background-repeat: no-repeat;
  background-size: cover;
  &.report-bg{
    background-color: #06141a;

  }
  .report-header {
    padding: 26px;
    &.report-header-bg{
      padding: 13px 26px;
      background-color: #020e13;

    }
  }
  .report-title {
    color: #fff;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 142.857% */
  }
  .report-text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    line-height: 20px; /* 125% */
  }
  .page-title {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .title {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    z-index: 2;
    color: #fff;
  }
  .title-image {
    margin-left: -5rem;
  }
  .page-padding{
    padding: 35px 32px;
  }
  .page-header-title{
    font-size: 16px;
    font-weight: 700;
  }
}
.swiper-button-prev, .swiper-button-next{
  color: #020e13 !important;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem !important;
  height: 2rem !important;
  min-width: 2rem !important;
  min-height: 2rem !important;
  top: 30%;

  &::after{
    background-color: #fff;
    border-radius: 50%;
    font-size: 20px !important;
    padding: 1rem;
    min-width: 52px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.oranlar {
  display: flex;
  justify-content: center;
  height: calc(100% - 111px);
  margin-top: auto;
}
.oranlar-detay {
  display: flex;
  flex-direction: column;
  gap: 33px;
  justify-content: center;
}
.oran-div {
  position: relative;
  width: 377px;
  height: 51px;
  padding: 0 15px;
  background-color: #222;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oran-text {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  z-index: 2;
}
.absolute-bg {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px 3px 3px 10px;
}
.bg-green {
  background-color: #58cc02;
}
.bg-green-two {
  background-color: #357c00;
}
.bg-green-three {
  background-color: #204b00;
}
.fotolar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
}
.foto {
  width: 20%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.foto-div {
  background: #d9d9d9;
  width: 100%;
  border-radius: 10px;
  padding: 3px;
  height: 200px;
}
.foto img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 8px;
}
.jobreport-name{
  font-size: 24px;
  font-weight: 300;
  text-transform: capitalize;
  border-bottom: 1px solid #FFF;
  padding: 30px 60px;
}
.accordion-button:not(.collapsed){
  background-color: #DDFFC4 !important;
  color: #4aad00;
  font-weight: 500;
}
.accordion-button:focus{
  border-color: #DDFFC4 !important;
  box-shadow: none !important;
}