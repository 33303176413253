@font-face {
    font-family: "Gopher Text";
    font-weight: 200;
    src: url("../fonts/GopherText-Thin.ttf") format("truetype");;
}
@font-face {
    font-family: "Gopher Text";
    font-weight: 300;
    src: url("../fonts/GopherText-Light.ttf") format("truetype");;
}
@font-face {
    font-family: "Gopher Text";
    font-weight: 400;
    src: url("../fonts/GopherText-Regular.ttf") format("truetype");;
}
@font-face {
    font-family: "Gopher Text";
    font-weight: 500;
    src: url("../fonts/GopherText-Medium.ttf") format("truetype");;
}
@font-face {
    font-family: "Gopher Text";
    font-weight: 600;
    src: url("../fonts/GopherText-Bold.ttf") format("truetype");;
}
@font-face {
    font-family: "Gopher Text";
    font-weight: 700;
    src: url("../fonts/GopherText-Heavy.ttf") format("truetype");;
}

@font-face{
    font-family: "Catesque";
    font-weight: 300;
    src: url("../fonts/Catesque/catesque-light.otf") format("opentype");
}
@font-face{
    font-family: "Catesque";
    font-weight: 400;
    src: url("../fonts/Catesque/catesque-regular.otf") format("opentype");
}
@font-face{
    font-family: "Catesque";
    font-weight: 500;
    src: url("../fonts/Catesque/catesque-medium.otf") format("opentype");
}
@font-face{
    font-family: "Catesque";
    font-weight: 600;
    src: url("../fonts/Catesque/catesque-bold.otf") format("opentype");
}
@font-face{
    font-family: "Catesque";
    font-weight: 700;
    src: url("../fonts/Catesque/catesque-black.otf") format("opentype");
}

@font-face{
    font-family: "Gilroy";
    font-weight: 800;
    src: url("../fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");

}