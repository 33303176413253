@import "../../assets/scss/colors.scss";
#movingCircle {
  background-color: $darkblue;
}
#movingCircleMini {
  background-color: $black;
  width: 100%;
  height: calc(38.45rem - 75px);
}
.circle-popup{
  
  background-color: $white;
  margin-bottom: 1rem;
  padding: 1.3rem 4.1rem;
  border-radius: 0.25rem;
  text-align: center;
}
.questions {
  color: $black;
  .question-q {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2.05rem; /* 170.833% */
    letter-spacing: 0.06rem;
    color: $black;
  }
  .alphabet {
    color: #c1c1c1;

    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 125% */
    letter-spacing: 0.06rem;
  }
  .question-area {
    min-height: calc(100dvh - 75px);
    padding: 3rem 0;
    padding-bottom: 190px;
    height: 100%;
    display: flex;
    align-items: center;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &.dynamic-area {
      min-height: calc(100dvh - 75px);
      height: 100%;
      padding: 3rem 0;
      padding-bottom: 190px;
    }
  }
  .shk {
    color: #c1c1c1;

    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 125% */
    letter-spacing: 0.06rem;
  }
  .fixed-header {
    width: 100%;
    height: 75px;
    background-color: $blue;
    display: flex;
    align-items: center;
    justify-content: space-between;

    * {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
  .selected-color {
    background-color: #f8fae5;
    border-radius: 50%;
    width: 2.8rem;
    height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .color-box {
      width: 2.2rem;
      height: 2.2rem;
      min-width: 2.2rem;
      border-radius: 50%;
    }
  }
  .time-left {
    padding: 0.85rem 1.6rem;
    background-color: $darkblue;
    border-radius: 5rem;
    min-width: 6.7rem;
    .time-text {
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem; /* 125% */
      letter-spacing: 0.06rem;
      color: $green;
      margin-bottom: 0;
      text-align: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
  .number {
    width: 50px;
    height: 50px;
    background-color: #1629de;
    color: #fff;
    display: grid;
    place-items: center;
    font-size: 24px;
    font-weight: 600;
    border-radius: 0.35rem;
    border-top: 2px solid #3b4eff;

    border-right: 2px solid #3b4eff;

    border-bottom: 5px solid #3b4eff;

    border-left: 2px solid #3b4eff;
  }
  .number-dropper {
    width: 50px;
    height: 50px;
    background-color: #fff;
    display: grid;
    place-items: center;
    font-size: 24px;
    font-weight: 600;
    border: 1px solid #111;
    border-radius: 4px;
    border-radius: 0.35rem;
    border-top: 2px solid #3b4eff;
    border-right: 2px solid #3b4eff;
    border-bottom: 5px solid #3b4eff;
    border-left: 2px solid #3b4eff;
    background: rgba(109, 123, 255, 0.5);
    &.number-dropper-lg {
      width: 4.3rem;
      height: 4.3rem;
      border-radius: 0.35rem;
      border-top: 2px solid #e5e5e5;
      border-right: 2px solid #e5e5e5;
      border-bottom: 5px solid #e5e5e5;
      border-left: 2px solid #e5e5e5;
      background: #fff;
      &.active {
        border-top: 2px solid #3b4eff;
        border-right: 2px solid #3b4eff;
        border-bottom: 5px solid #3b4eff;
        border-left: 2px solid #3b4eff;
      }
    }
    &.bg-white {
      background-color: #e5e5e5;
      border-top: 2px solid #e5e5e5;

      border-right: 2px solid #e5e5e5;

      border-bottom: 5px solid #e5e5e5;

      border-left: 2px solid #e5e5e5;
      width: 4.3rem !important;
      height: 4.3rem !important;
    }
  }
  .number-dropper.lg {
    width: 100px;
    height: 100px;
  }
  .number-dropper.active {
    background-color: #3468c0;
  }
  .mb6 {
    margin-bottom: 6rem;
  }
  .gap15 {
    gap: 1.5rem !important;
  }
  .mb-15 {
    margin-bottom: 1.5rem !important;
  }
  .symbol {
    width: 50px;
    height: 50px;
    background-color: #fff;
    display: grid;
    place-items: center;
    font-size: 24px;
    font-weight: 600;
    border: 1px solid #111;
    border-radius: 4px;
    color: #3b4eff;
    &.symbol-router {
      width: fit-content;
      height: fit-content;
      border: none;
      color: $black;
      &.active {
        background-color: $white;
      }
      .symbol-arrow {
        width: 4.3rem !important;
        height: 4.3rem !important;
        border-radius: 0.35rem;
        border-top: 2px solid #e5e5e5;
        border-right: 2px solid #e5e5e5;
        border-bottom: 5px solid #e5e5e5;
        border-left: 2px solid #e5e5e5;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }
  }
  .symbol.lg {
    width: 100px;
    height: 100px;
  }
  .symbol-text {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .symbol.active {
    background-color: #86a7fc;
  }
  .droppable-area {
    min-height: 100px;
  }
  /* .droppable-area.active {
    background-color: #6da4aa;
  } */
  .answers-check {
    border-radius: 0.5rem;
    border-top: 2px solid #e5e5e5;
    border-right: 2px solid #e5e5e5;
    border-bottom: 5px solid #e5e5e5;
    border-left: 2px solid #e5e5e5;
    padding: 0.7rem;
    width: 100%;
    cursor: pointer;
    transition: 0.5ms all;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 125% */
    letter-spacing: 0.06rem;
    position: relative;
  }
  .form-check-photo{
    height: 100%;
  }
  .answer-check-photo{
    border: none;
    p{
      min-height: 56px;
    }
    .check-circle{
      width: 1.2rem;
      height: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      border-radius: 50%;
      border: 2px solid #E5E5E5;
      display: inline-block;
      margin-top: 4px;
    }
    .span-image-container{
      padding-left: 1.7rem;
      display: inline-block;
    }
    img{
      width: 100%;
      max-width: 100%;
      min-height: 13.5099rem;
      height: 100%;
      border-top: 2px solid #E5E5E5;
      border-right: 2px solid #E5E5E5;
      border-bottom: 5px solid #E5E5E5;
      border-left: 2px solid #E5E5E5;
      border-radius: .5rem;
    }
  }
  .form-check-input-photo:checked + .answer-check-photo{
    background-color: transparent !important;
    .check-circle{
      background-color: $darkgreen;
    }
  }
  .form-check-input:checked + .answers-check {
    background-color: $lightgreen;
    color: $darkgreen;
    border-color: $darkgreen;
    .alphabet {
      color: $darkgreen;
    }
  }
  .answers-check.active {
    background-color: rgba(59, 78, 255, 0.15);

    color: #3b4eff;
    border-color: #3b4eff;
  }
  .answers-check.active.selected {
    
    color: $white;
    background-color: rgba(var(--bg-color),0.7);
    border-color: rgb(var(--bg-color));
  }
  .form-check-input:checked + .answers-check-image {
    border: none;
    background-color: transparent !important;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba($color: $darkgreen, $alpha: 0.25);
      border-radius: 0.5rem;
      border-top: 2px solid $darkgreen;
      border-right: 2px solid $darkgreen;
      border-bottom: 5px solid $darkgreen;
      border-left: 2px solid $darkgreen;
    }
  }
  .answers-check:hover {
    background-color: #f8f8f8;
    border-color: #e5e5e5;
    -webkit-transition: background-color 250ms ease-in-out;
    -ms-transition: background-color 250ms ease-in-out;
    transition: background-color 250ms ease-in-out;
  }
  .answers-check-image {
    width: 100%;
    padding: 2.27rem 2.1rem 0.7rem 0.55rem;
  }
  .answers-check-image img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .moving-circle-pointer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: $black, $alpha: 0.8);
    position: fixed;
    top: 75px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 75px);
    &.absolute {
      position: absolute;
      height: calc(38.45rem - 75px);
      width: 100% !important;
    }
  }
  .btn {
    min-width: 250px;
  }
  .btn-primary {
    background-color: #0f678f;
    border-color: rgb(15, 103, 143);
    padding: 0.5rem 0;
  }
  .btn-primary:hover {
    background-color: rgba(15, 103, 143, 0.9);
    border-color: rgb(15, 103, 143, 0.8);
  }
  .image-kroki {
    width: 100%;
    height: 300px;
  }
  .image-kroki img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .box-triagle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $blue;
    position: relative;
    display: grid;
    place-items: center;
    font-weight: 600;
    font-size: 25px;
    color: #fff;
  }
}
.progress-primary .progress-bar {
  background-color: $blue;
  border-radius: 5rem;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    position: absolute;
    top: 0.1rem;
    left: 0.25rem;
    height: 0.25rem;
    background-color: rgba(178, 186, 255, 0.3);
    border-radius: 5rem;
  }
}
.password-button {
  cursor: pointer;
  &:hover {
    color: $blue;
  }
}
.password-numbers-dropper {
  cursor: pointer;
  &:hover {
    border-color: $blue !important;
    background-color: rgba(59, 78, 255, 0.15) !important;
  }
}
.grid-area {
  max-height: 100%;
  max-width: 100%;
  img {
    width: 50px !important;
    height: 50px !important;
  }
  .main-image {
    width: 250px !important;
    height: 250px !important;
  }
}
.grid-question {
  grid-area: 2 / 2 / 4 / 4;
}

.answer-checkbox {
  height: 10.15rem;
  width: 10.15rem;
  padding: 2.3rem 2.8rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 5.4956rem;
    height: 5.4956rem;
  }
}
.question-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100dvw;
  padding: 1.55rem;
  background-color: $black;
  height: 105px;
  z-index: 9999;
}
