$darkblue : #3C3744;
$blue : #3B4EFF;
$lightblue: #B4C5E4;
$black :#222;
$white :#FFF;
$gray : rgba(255, 255, 255, 0.75);
$yellow : #D6B33E;
$semigray : #787281;
$link : #090C9B;
$yellowfull:#FFC800;
$green : #58cc02;
$lightgreen:#DDFFC4;
$darkgreen : #4aad00