@import "./colors.scss";
$basemargin: 14rem;
$basemargin_mobile: 8rem;

.main-section {
    height: calc(100dvh - 110px);
    background-image: url("../../../public/images/hero.png");
    background-position: bottom 50% center;
    background-repeat: no-repeat;
    background-size: 100% 50dvh;
    position: relative;
    .text-area {
        padding-top: 3rem;
    }
    .main-text {
        color: #fff;
        font-family: "Gopher Text";
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.125rem;
        .text-line {
            color: $lightblue;
            font-weight: 500;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                height: 0.25895rem;
                width: 100%;
                transform: rotate(-1.73deg);
                background: $yellow;
            }
        }
    }
    .what-section {
        position: absolute;
        left: 50%;
        bottom: 3%;
        transform: translateX(-50%);
    }
}
.offers-you {
    margin-top: $basemargin;
}
.section-title {
    color: $white;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.7rem; /* 150% */
    letter-spacing: 0.09rem;
}

.what-card {
    display: flex;
    gap: 1rem;
    padding: 0.95rem 1.05rem;
    background-color: $white;
    border-radius: 0.25rem;
    width: 27.95205rem;
    box-shadow: 0px 4px 4px 0px rgba(34, 34, 34, 0.1);
    position: relative;
    &::before {
        content: "";
        width: 26.3rem;
        height: 5.95rem;
        position: absolute;
        bottom: -0.4rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        border-radius: 0.25rem;
        z-index: -1;
        box-shadow: 0px 4px 4px 0px rgba(34, 34, 34, 0.1);
    }
    &::after {
        content: "";
        width: 24.4rem;
        height: 5.5rem;
        position: absolute;
        bottom: -0.8rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        border-radius: 0.25rem;
        z-index: -2;
        box-shadow: 0px 4px 4px 0px rgba(34, 34, 34, 0.1);
    }

    .card-title {
        color: $black;
        font-family: "Catesque";
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.35rem;
    }
    .card-text {
        color: $black;
        font-family: "Catesque";
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1rem;
    }
}
.offers-you-card {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3.75rem;
    .icon-box {
        border: 1px solid rgba($color: $white, $alpha: 0.5);
        padding: 0.35rem;
        border-radius: 0.65rem;
        .icon-background {
            background-color: $white;
            border-radius: 0.5rem;
            padding: 0.5rem;
            display: grid;
            place-items: center;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .content-title {
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.5rem;
            letter-spacing: 0.06rem;
            color: $white;
            margin-bottom: 0;
        }
        .content-text {
            color: rgba($color: $white, $alpha: 0.75);
            line-height: 1.5rem;
            letter-spacing: 0.05rem;
            margin-bottom: 0;
        }
    }
}

.how-it-works {
    margin-top: $basemargin;
    .how-it-list {
        .title {
            color: $white;
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: 2.45rem;
        }
        .list {
            list-style-type: auto;
            padding-left: 1rem;
            li {
                font-size: 1.2rem;
                color: $white;
                margin-bottom: 1.2rem;
                font-weight: 400;
                padding-left: 1rem;
            }
        }
    }
}
.partners {
    margin-top: $basemargin;
    .partner-text {
        color: rgba($color: $white, $alpha: 0.75);
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: 0.04rem;
    }
    .companies {
        border-top: 1px solid rgba($color: $white, $alpha: 0.5);
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.5);
        padding: 3rem 0;
    }
}
.test-wrapper {
    margin-top: $basemargin;
    .game-area {
        width: 100%;
        margin-top: 1.5rem;
        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
        }
        .home-question-steps {
            position: absolute;
            bottom: 2.9rem;
            left: 2rem;
            color: #fff;
            .step {
                color: rgba(255, 255, 255, 0.5);
                font-size: 1.2rem;
                font-weight: 300;
                line-height: 1.2rem; /* 100% */
                letter-spacing: 0.06rem;
                &.active {
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.2rem;
                    letter-spacing: 0.06rem;
                    color: $white;
                }
            }
        }
    }
}
.faq {
    margin-top: $basemargin;
    padding: 12.05rem 0;
    background-color: $white;
    .faq-title {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 3.1rem;
        letter-spacing: 0.125rem;
        color: $darkblue;
    }
}
.contact {
    margin-top: $basemargin;
    .contact-title {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 2.7rem; /* 108% */
        letter-spacing: 0.125rem;
    }
    .contact-title-big {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 2.7rem; /* 108% */
        letter-spacing: 0.125rem;
    }
}
.info {
    margin-top: $basemargin;
    .info-title {
        font-size: 2rem;
        font-weight: 600;
    }
}
.question-mini {
    background-color: $white;
    width: 100%;
}

@media (max-width: 990px) {
    .offers-you {
        margin-top: $basemargin_mobile;
    }
    .info {
        margin-top: $basemargin_mobile;
    }
    .contact {
        margin-top: $basemargin_mobile;
    }
    .faq {
        margin-top: $basemargin_mobile;
    }
    .test-wrapper {
        margin-top: $basemargin_mobile;
    }
    .partners {
        margin-top: $basemargin_mobile;
    }
    .how-it-works {
        margin-top: $basemargin_mobile;
    }
    .what-card {
        width: 100% !important;
        &::before {
            width: 95% !important;
        }
        &::after {
            width: 90% !important;
        }
    }
    .main-section {
        height: calc(100dvh - 75px) !important;
        background-position: bottom 50% center;
        background-size: contain !important;
        .main-text {
            font-size: 2rem;
            text-align: center;
            span.fw-medium {
                line-height: 2;
            }
        }
    }
}
