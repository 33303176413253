@import "../../../assets/scss/colors.scss";

footer {
    margin-top: 6.4rem;
    border-top: 1px solid $white;
    padding-top: 1.1rem;
    .footer-menu {
        padding: 3.25rem 0;
        border-bottom: 1px solid $white;
        h6 {
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }
        .menu-list {
            list-style: none;
            padding: 0;
            li {
                margin-bottom: 1rem;
            }
            .footer-link {
                font-size: 0.9rem;
                color: rgba($color: $white, $alpha: 0.75);
            }
        }
        .email-area {
            margin-bottom: 1.25rem;
            .email-input {
                padding: 1.7rem;
                padding-right: 4.5rem;
                height: 3.8rem;
                color: $white;
                background-color: transparent;
                outline: none;
                border: 1px solid rgba($color: $white, $alpha: 0.5);
                border-radius: 5rem;
                width: 100%;
                &::placeholder {
                    color: rgba($color: $white, $alpha: 0.75);
                }
                
            }
            .email-button {
                width: 3.8rem;
                height: 3.8rem;
                background-color: $lightblue;
                outline: none;
                border: none;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .email-info{
            color: rgba($color: $white, $alpha: 0.75);
            font-size: 0.8rem;
            font-weight: 300;
            line-height: 1.15rem;
        }
    }
    .footer-sub-menu{
        padding: 1.3rem 0 0.95rem;
        color: $semigray;
       
    }
}
