@import "./colors.scss";
.profile {
    padding-top: 3.2rem;
    padding-bottom: 6rem;
    .test-result {
        margin-top: 6rem;
    }
    .profile-info {
        gap: 1.25rem;
        .profile-picture {
            width: 7.5rem;
            height: 7.5rem;
            border-radius: 50%;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            * {
                width: 100%;
                height: 100%;
                max-width: 100%;
            }
        }
        .profile-data {
            .user-name {
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 1.5rem;
            }
        }
    }
    .profile-update-input {
        background-color: transparent;
        color: #fff;
        &:focus {
            background-color: transparent !important;
            color: $white;
        }
    }
    .job-info {
        .job-review {
            .job-name {
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 1.6rem; /* 114.286% */
                color: $yellowfull;
            }
        }
        .skill-name {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 0.9rem;
            margin-bottom: 0; /* 112.5% */
        }
    }
    .divider {
        background: rgba(255, 255, 255, 0.5);
        height: 0.025rem;
        margin-top: 4.75rem;
        margin-bottom: 2.75rem;
    }
    .result-text {
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.15rem; /* 238.889% */
    }
    .download-file {
        margin-top: 5rem;
    }
    .fs09 {
        font-size: 0.9rem;
        line-height: 2.15rem;
        font-weight: 500;
    }
    .password-reset-btn {
        color: $lightblue;
        text-decoration: underline;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1rem; /* 125% */
        letter-spacing: 0.04rem;
        padding: 0;
    }
}

