@import "./colors.scss";

.before-questions {
    width: 100%;
    height: 100%;
    color: $black;
    background-color: #fff;
}
.questions-header {
    padding: 0.95rem 1.3rem;
    box-shadow: 0px 4px 30px 0px rgba(34, 34, 34, 0.1);
    height: 4.15rem;
    display: flex;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 9999;
}
.main-area {
    height: calc(100dvh - 4.15rem);
    width: 100vw;
    max-width: 100%;
    .info-h6 {
        color: rgba(34, 34, 34, 0.75);
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5rem; /* 125% */
        letter-spacing: 0.06rem;
    }
    .info-big-text {
        color: #222;
        font-size: 2.45rem;
        font-weight: 600;
        line-height: 3.2rem; /* 130.612% */
        letter-spacing: 0.1225rem;
    }
}
.payment-inform {
    padding-bottom: 4.75rem;
    .inform-title {
        font-size: 2.3rem;
        font-weight: 400;
        line-height: 3.25rem; /* 141.304% */
        letter-spacing: 0.115rem;
        color: $black;
    }
}
.payment-card-wrapper{
    height: 37.4rem;
    overflow: auto;

}
.payment-card {
    border-radius: 0.55rem;
    border: 3px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    .payment-card-body {
        padding: 1.85rem 2.3rem;
    }
    .card-header {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5rem; /* 125% */
        letter-spacing: 0.06rem;
        border-bottom: 2px solid #e5e5e5;
        padding-bottom: 0.4rem;
        padding-right: 0.6rem;
        width: fit-content;
    }
    .card-title {
        color: rgba(34, 34, 34, 0.5);

        font-family: "Gopher Text";
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.05rem; /* 128.125% */
        letter-spacing: 0.08rem;
    }
    .card-text {
        color: rgba(34, 34, 34, 0.5);

        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.2rem; /* 150% */
        letter-spacing: 0.05rem;
    }
    .price {
        color: rgba(34, 34, 34, 0.5);
        font-size: 2.45rem;
        font-weight: 600;
        line-height: 3.2rem; /* 130.612% */
        letter-spacing: 0.1225rem;
    }
    .subtext {
        color: rgba(34, 34, 34, 0.5);
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.1rem; /* 110% */
        letter-spacing: 0.05rem;
    }
    .payment-option-check:checked + label .payment-option {
        border-color: $darkgreen;
        background-color: #FFF;
        color: $darkgreen;
        * {
            color: $darkgreen;
        }
    }
    .payment-options {
        margin-top: 2.65rem;
        margin-bottom: 4.3rem;
        .payment-option {
            display: flex;
            border-radius: 0.5rem;
            gap: 0.5rem;
            background: #e5e5e5;
            padding: 0.75rem 1rem;
            align-items: center;
            justify-content: center;
            color: #838383;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.5rem; /* 125% */
            letter-spacing: 0.06rem;
            border: 4px solid transparent;
        }
    }
    .payment-input-label {
        color: #222;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem; /* 125% */
        letter-spacing: 0.06rem;
        margin-bottom: 0.75rem;
    }
    .payment-input {
        border-radius: 0.5rem;
        border: 2px solid #e5e5e5;
        height: 3.2rem;

        &:focus {
            box-shadow: none;
        }
    }
    
    .payment-input-no-required {
        border-radius: 0.5rem;
        border: 2px solid #e5e5e5;
        height: 3.2rem;

        &:focus {
            box-shadow: none;
        }
    }
    .card-icon {
        position: absolute;
        top: 50%;
        right: 2.5rem;
        transform: translateY(-50%);
        padding: 0.75rem 0;
    }
    .order {
        margin-top: 1.1rem;
        .orderprice {
            color: $blue;
            font-weight: 600;
        }
        .orderinfo {
            font-size: 0.8rem;
            font-weight: 300;
        }
    }
    .payment-card-footer {
        background: #e5e5e5;
        padding: 0.65rem 1.1rem;
        margin-top: auto;
        .footer-text {
            font-size: 0.7rem;
            line-height: 1rem; /* 142.857% */
            letter-spacing: 0.035rem;
        }
        .payment-date {
            color: $black;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5rem; /* 150% */
            letter-spacing: 0.05rem;
        }
    }
}
.payment-area {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.payment-check:checked + label .payment-card {
    border-color: $darkgreen;
    background-color: $lightgreen;
    * {
        color: $darkblue;
    }
}

.finish {
    padding: 4.75rem 0 3.75rem;
    .finish-title {
        margin-top: 1rem;
        font-size: 2rem;
        font-weight: 500;
        line-height: 2.05rem;
        letter-spacing: 0.1rem;
    }
    .top-area {
        margin-bottom: 4.7rem;
    }
    .finish-text {
        color: #222;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 2.05rem; /* 170.833% */
        letter-spacing: 0.06rem;
    }
}
