@import "./colors.scss";
@import "./home.scss";

$margin-desktop: 10rem;
$margin-mobile: 5rem;

.main-wrapper {
    height: calc(100dvh - 110px);
    background-image: url("../../../public/images/eduvery.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    .page-title {
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 3.6rem;
    }
    .page-text {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .absolute-content {
        position: absolute;
        left: 50%;
        bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateX(-50%);
        cursor: pointer;
    }
}
.how-it-works {
    margin-top: $margin-desktop;
    .main-title {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 3.6rem;
    }
    .work-content {
        margin-bottom: 3rem;
        .number {
            width: 2.6rem;
            height: 2.6rem;
            min-width: 2.6rem;
            border-radius: 50%;
            background-color: $blue;
            color: #fff;
            display: grid;
            place-items: center;
            font-size: 1.3rem;
            font-weight: 600;
        }
        .work-content-title {
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.5rem;
        }
        .work-content-text {
            color: rgba($color: $white, $alpha: 0.6);
            margin-bottom: 0;
        }
    }
}
.offer-request {
    margin-top: $margin-desktop;
    .offer-title {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 3.6rem;
    }
    .ranger {
        gap: 2.45rem;

        .ranger-text {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.2rem;
            margin-bottom: 0;
        }
        .rangeslider-horizontal .rangeslider__fill{
            background-color: $blue ;
            position: relative;
            &::after{
                content: '';
                width: 100%;
                position: absolute;
                top: 0.1rem;
                left: 0.25rem;
                height: 0.25rem;
                background-color: rgba(178, 186, 255, 0.30);
                border-radius: 5rem;
            }
        }
        
        .rangeslider-horizontal .rangeslider__handle:after{
            background-color: $blue;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}
@media (max-width:990px) {
    .main-wrapper{
        background-image: none;
        .absolute-content{
            position: relative;
            bottom: 0;
            margin-top: 40px;
        }
    }
    .main-area{
        height: 100%;
        padding-bottom: 50px;
    }
    .edutest-main-wrapper{
        height: 100%;
        margin-top: 110px;
    }
}